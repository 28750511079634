import React from "react";
import { Link } from "react-router-dom";

function CateringSection() {
  return (
    <div className="bg-black pb-32 pt-16 border-b-white border-b-4 ">
      <div className="px-8 md:px-36"><h2 className="text-white text-2xl capitalize leading-10">In case you were wondering; we offer catering as well. Our local Tampa Florida catering service
is available for any and all of your special occasions. If you want some fun to your next birthday,
or just go crazy with some down home, very affordable comfort food on your wedding day, just tap below to get started.</h2> <h4  className="text-3xl animate-bounce mt-4" >👇</h4 ></div>
      <div className="mt-2">
        <Link to={"/catering"}><h1 className="text-white italic text-4xl hover:text-zinc-400">
          Need Catering? (Click Here)
        </h1></Link>
        
      </div>
    </div>
  );
}

export default CateringSection;
