import React from "react";

function WelcomeSection() {
  return (
    <div className="bg-yellow-500 py-16 md:py-36">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:px-32 items-center">
        <div className="md:text-left px-2">
          <h2 className="text-7xl text-center font-extrabold">
            Welcome To The Family!👋{" "}
          </h2>
          <h3 className="pt-6 mx-6 md:mx-2 text-lg pb-6 md:text-xl">
          ​From sun-up to sun-down, day in and day out, Grandma worked in her garden or yard. She
often remarked “I just love to see things grow”. She nourished her garden and yard with the
same love she gave her family knowing that it provided the substance for their bodies to grow,
be strong, and healthy. This was past down to her children who continue the passion of
cooking and providing healthy meals to their families. And thus grew the concept of “
Grandma’s soul food ”.
As our passion grows, we strived to provide the same wholesome meals that were prepared for
us as a child. Time in the kitchen to prepare these meals reduced quality time with our family. 
We always believed there was no reasons why soul food could not be fast food. Our busy
lifestyles often drive us toward questionable healthy foods that is readily available. 
We at “Two Grandma’s Soul Food “ strive to provide a healthy affordable fast-food alternative
to the everyday family that is available when you need it and revive the traditions of combining
southern and Caribbean style cooking.
          </h3>
        </div>
        <div className="px-6 ">
          <img
            src="
          https://ik.imagekit.io/jsklle928/Two_Grandmas_Soul_Food/family.jpeg?updatedAt=1688417833498"
            alt="family"
          />
        </div>
      </div>
    </div>
  );
}

export default WelcomeSection;
