import React from 'react'

function FoodSection() {
  return (
    <div className='pt-24 pb-24'>
        <div><h1 className=' text-7xl md:text-8xl font-extrabold'>ABOUT US</h1></div>

        <div className='flex flex-wrap justify-around items-center md:mx-32 pt-16 '>
            <div>
<img style={{width: 400}} src='https://ik.imagekit.io/jsklle928/IMG_2837_2.jpg?updatedAt=1688421892529' alt='logo'/>
            </div>
            <div className='md:w-1/2 text-left md:text-2xl leading-8 md:leading-10 py-4 px-4'>
<h2>We are one of the best soul food truck in the Tampa Bay Area. And we are on the move to a
neighborhood near you. Offering up some of the most amazing food you have ever tasted with
a side dish of down-home hospitality. Two Grandma’s Soul Food Truck is the mobile soul food
extravaganza you just cannot miss. Are you looking for a convenient place to grab some
comfort food in Tampa or the Tampa Bay area? Do not look any further! Our mobile culinary
haven is always nearby when you crave some eats that you simply just cannot get anywhere
else.</h2>
            </div>

        </div>
        <div>

        </div>

    

        <div className='md:mx-32 text-left md:text-2xl  leading-8  md:leading-10 pt-2 md:pt-12 px-4 '><h2>    <span className='md:pl-16'>Let’s</span> talk about good food for a second. We have many and many years experience in the
culinary field. We know what it takes to deliver great food and when we discovered that we
could take this passion on the road, we jumped at the chance. We offer up anything from pork
and beef dishes, to hearty chicken meals that leave you satisfied for a long, long time. Don’t
worry, we dish up a good share of veggies platter , cornbread, special soups (soup joumou)and
light dishes too, so if you’re in the mood for something small to munch on during your busy
workday, Two Grandma’s Soul Food truck in Tampa is for you. What we really specialize in,
however, is satisfying the biggest appetites, for the least amount of money you can spend.
Show us where it says you need to spend a ton of cash to get a ton of food that is right,
nowhere. We serve up great food on the best food truck in Tampa Florida, so whether you’re
on the move, on a break from work, or just on a mission for fill your belly and have some fun,
Two Grandma’s has got you covered!</h2></div>
<div className='pt-12 flex flex-wrap justify-around md:mx-32 pb-12 '>

<img style={{minWidth: 300, maxWidth: 375, padding:12}} className="mx-4" src='https://ik.imagekit.io/jsklle928/Two_Grandmas_Soul_Food/Oxtails?updatedAt=1688254703242'
alt='' />
<img style={{minWidth: 300, maxWidth: 375, padding:12}} className="mx-4"  src="https://ik.imagekit.io/jsklle928/Two_Grandmas_Soul_Food/Smothered_Pork_Chops?updatedAt=1688254553067" alt='' />
<img style={{minWidth: 300, maxWidth: 375, padding:12}} className="mx-4"  src="https://ik.imagekit.io/jsklle928/Two_Grandmas_Soul_Food/Smothered_Chicken?updatedAt=1688254322866" alt='' />
</div>
<div className='px-4 md:mx-32 text-left md:text-2xl  leading-8  md:leading-10  pb-12'>We are on a mission to offer what any other food truck in Tampa Florida just cannot. Great
food at an actual price you can afford. We were on a mission to bring the area something it
hasn’t seen before.How great would it be to take a quick break from work and hop on over to
Two Grandma’s soul food for affordable soul food that will make you wish you had two
stomachs? How great would it be to have a Tampa food truck in your back yard that you can
depend on for consistently great service and prices? Yeah. We went out and made this idea a
reality. So come for the food but stay for the laughter and good times. The best food truck in
Tampa has got to live up to its name, so we are serving up some smiles to go along with your
good food.</div>
    </div>
  )
}

export default FoodSection