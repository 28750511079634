import "./App.css";
import HeroHeader from "./Components/HeroHeader";
import LocationSection from "./Components/LocationSection";
import NavBar from "./Components/NavBar";
import MenuSection from "./Components/MenuSection";
import BookTheTruckSection from "./Components/BookTheTruckSection";
import TestimonialSection from "./Components/TestimonialSection";
import WelcomeSection from "./Components/WelcomeSection";
import CateringSection from "./Components/CateringSection";
import Footer from "./Components/Footer";
import { ArrowUpwardRounded } from "@mui/icons-material";
import FoodSection from "./Components/FoodSection";

function App() {

  function scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  return (
    <div className="App overflow-hidden ">
      <div>
        <NavBar />
      </div>
      <div>
        <HeroHeader />
      </div>
      <div><FoodSection/></div>
      <div>
        <LocationSection />
      </div>
      <div>
        <MenuSection />
      </div>
      <div>
        <BookTheTruckSection />
      </div>
      <div>
        <TestimonialSection />
      </div>
      <div>
        <WelcomeSection />
      </div>
      <div>
        <CateringSection />
      </div>
      <div>
        <Footer />
      </div>
      <div onClick={scrollToTop} className="fixed bottom-10 right-5 bg-red-600 hover:bg-red-700  rounded-full p-1" ><ArrowUpwardRounded style={{fontSize: 50, color: "white"}}/></div>
     
    </div>
  );
}

export default App;
